h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: $color-dark;
  font-weight: 700;
}

h2 {
  font-weight: 700;
  font-size: 28px;
}

h3 {
  font-size: 14px;
  font-weight: 550;
}

.headline-underline {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid $color-gray-300;
  margin-top: 36px;
  margin-bottom: 24px;
}
