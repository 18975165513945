.form-control {
  background: transparent;
  border: 1px solid $color-gray-400;
  border-radius: $border-radius-100;

  &:not([type='radio']):not([type='checkbox']) {
    min-height: $btn-input-height;
  }

  &:focus {
    box-shadow: none;
    background: transparent;
    border-color: $color-blue-100;
  }
  &::placeholder {
    color: $color-gray-100;
  }

  &:disabled {
    background: $color-gray-200;
    border-color: transparent;
  }
}

.form-group {
  margin-bottom: 20px;
}

.input-group {
  border-radius: $border-radius-100;
  border: 1px solid $color-gray-400;

  &:has(:disabled) {
    background: $color-gray-200;
    border-color: transparent;
  }

  .form-control {
    border: none;
  }
  &:focus-within {
    border: 1px solid $color-blue-100;
    box-shadow: none;
  }
  .btn {
    border-radius: $border-radius-100;
    border: none;
    height: $btn-input-height;

    &:hover {
      background: none;
      color: $color-dark;
    }
    i {
      font-size: 24px;
      color: $color-blue-100;
      display: flex;
      align-items: center;
    }
  }
}
.form-label {
  font-size: 14px;
  font-weight: 600;
}

.filter-checkbox {
  width: auto;
  margin-top: 38px;
}

.ng-select {
  font-size: 13px;

  &:focus {
    border-color: $color-blue-100;
    box-shadow: none;
  }
  .ng-placeholder {
    font-size: 13px;
    color: $color-gray-100;
    top: auto !important;
  }

  .ng-select-container {
    color: $color-black;
    border-radius: $border-radius-100;
    border: 1px solid $color-gray-400;
    height: $btn-input-height !important;

    &:hover {
      box-shadow: none;
    }

    .ng-input {
      top: 8px !important;

      & > input {
        padding-inline: 0;
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $color-gray-400;
    box-shadow: none;
  }

  &.ng-select-multiple {
    .ng-select-container {
      height: auto !important;
      min-height: 42px;

      .ng-value-container {
        .ng-value {
          padding: 2px 4px;
          border-radius: 6px;
          word-break: break-word;
          white-space: normal;
        }
      }
    }
  }

  .ng-clear-wrapper:hover .ng-clear {
    color: $color-black;
  }

  &.ng-select-disabled > .ng-select-container {
    background: $color-gray-200;
    border-color: transparent;
  }
}

.custom-control {
  display: block;
  &.inline {
    display: inline;
  }

  &-label {
    margin-left: 8px;
  }
}
.custom-checkbox input:disabled:checked ~ .custom-control-indicator {
  opacity: 0.5;
}
.custom-checkbox input:disabled ~ .custom-control-indicator {
  cursor: not-allowed;
}

.form-check .form-check-input {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border: 1px solid $color-gray-400;

  &:checked {
    background-color: $color-blue-100;
    border-color: $color-blue-100;
  }
}
