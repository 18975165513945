a {
  color: $color-dark;
}

.container-limit {
  display: block;
  width: 100%;
  max-width: 960px;

  &-sm {
    display: block;
    width: 100%;
    max-width: 660px;
  }

  &-xs {
    display: block;
    width: 100%;
    max-width: 480px;
  }
}

.thumbnail {
  width: 100px;
  border-radius: 8px;

  &-sm {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
}

// global custom scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #efefef;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 12px;

  &:hover {
    background-color: #6d6d6d;
  }
}

body {
  &::-webkit-scrollbar-thumb {
    background-color: #adb0b8;
  }
}

.color {
  &-primary {
    color: $color-primary;
  }
  &-danger {
    color: $color-red;
  }
}

.alert {
  padding: 12px;
  border-radius: $border-radius;
  border: none;
  color: $color-text-primary !important;

  i {
    font-size: 16px;
  }

  &-info {
    background-color: $color-blue !important;
    color: $color-blue-200 !important;
  }

  &-success {
    background-color: $color-surface-positive !important;
  }

  &-warning {
    background-color: $color-surface-warning !important;
  }
}
