.table-container {
  background: $color-white;
  border-radius: $border-radius;
  overflow: auto;
  table {
    width: 100%;
    thead {
      border-bottom: 1px solid $color-border-100;
      th {
        font-weight: 600;
        &.sortable {
          cursor: pointer;
          transition: 0.3s background;
          &:hover {
            background: $color-blue;
          }
          &:after {
            margin-left: 5px;
            font-family: Phosphor;
            color: $color-blue-100;
            vertical-align: -0.125em;
            -webkit-font-smoothing: antialiased;
            font-weight: 600;
          }
          &.asc {
            &:after {
              content: '\e136';
            }
          }
          &.desc {
            &:after {
              content: '\e13c';
            }
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid $color-gray-600;
        &:last-child {
          border-bottom: none;
        }
        td {
          font-size: 14px;
        }
      }
    }
  }
  th {
    padding: 24px 16px;
  }
  td {
    padding: 12px 6px;
    @include media-breakpoint-up(xl) {
      padding: 20px 16px;
    }
  }
  .fa-check {
    color: $color-green;
  }
  .fa-times {
    color: $color-red;
  }
  .actions {
    display: flex;
    .btn {
      margin: -12px 0;
    }
  }
}
