.map {
  height: auto;
  margin: unset;
  position: relative;

  .btn {
    height: 40px;
  }
}

.btn-float-container {
  position: absolute;
  z-index: 100;
  bottom: 30px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background: $color-white;
  border-radius: 100vh;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

  .btn {
    border-radius: 50%;
    border-color: $color-blue-200;

    &.disabled,
    &:disabled {
      background: $color-white;
      border-color: $color-border-100;

      i {
        color: $color-blue-200;
      }
    }

    &.active {
      background: $color-blue !important;
      border-color: $color-blue-200 !important;

      i {
        color: $color-blue-200;
      }
    }

    i {
      color: $color-blue-200;
    }
  }
}

.btn-action-container {
  position: absolute;
  z-index: 100;
  bottom: 40px;
  transform: translateX(-50%);
  left: 50%;
  padding: 8px;
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 100vh;
  overflow: hidden;

  .btn {
    padding: 12px 20px;
    border-radius: 0;

    &:first-child {
      border-radius: 100vh 0 0 100vh;
    }

    &:last-child {
      border-radius: 0 100vh 100vh 0;
    }
  }
}

.map-legend {
  font-size: 13px;
  padding: 6px 14px;
  background: $color-surface-tetritary-1;
  border-radius: 6px;
  display: inline-block;

  &-list {
    display: inline-flex;
    gap: 24px;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &-color {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $color-surface-secondary;

    &-overlay {
      background-color: #cb212f;
    }

    &-field {
      background-color: #e1e132;
    }

    &-sector {
      background-color: #124cff;
    }

    &-selected {
      background-color: #eb2d75;
    }
  }
}
