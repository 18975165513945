.pagination {
  display: flex;
  justify-content: center;
  margin-right: 16px;
}

.page-link.active,
.active > .page-link {
  background-color: $color-blue-100;
}
