.card {
  box-shadow: $box-shadow-300;
  border: none;
  background: $color-white;

  &-body {
    padding: 32px;
  }
}

.card-shadow {
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  background: $color-white;
}
