.nav-tabs {
  border-bottom: none;
  flex-wrap: nowrap;

  .nav-item {
    margin: 0 4px;
    position: relative;

    &:has(.nav-link:hover) {
      &:after {
        display: none;
      }
    }

    &:has(.nav-link.active) {
      &:after {
        content: '';
        left: 50%;
        transform: translate(-50%);
        top: 44px;
        width: calc(100% - 44px);
        height: 1px;
        background: $color-blue-100;
        display: block;
        position: absolute;
        border-radius: 2px;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:first-child {
      margin-left: 0;

      .nav-link {
        &.active {
          &:before {
            display: block !important;
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 6px;
            height: 6px;
            background-color: $color-white;
          }
        }
      }
    }

    .nav-link {
      border: none;
      border-radius: 8px 8px 0 0;
      font-size: 14px;
      padding: 14px 24px 20px;
      position: relative;
      color: rgba(58, 64, 79, 0.8);
      white-space: nowrap;
      background-color: #efefef;
      line-height: 2;
      transition:
        background-color 0.2s,
        transform 0.2s;

      &:not(.active) {
        transform: translateY(6px);

        &:hover,
        &:focus {
          background-color: #fbfbfb;
          transform: translateY(4px);
        }
      }

      &.active {
        position: relative;
        font-weight: 600;
        color: $color-dark;
        background-color: $color-white;

        &:before,
        &:after {
          content: '';
          position: absolute;
          height: 10px;
          width: 20px;
          bottom: 0;
        }

        &:after {
          right: -20px;
          border-radius: 0 0 0 8px;
          box-shadow: -10px 0 0 0 $color-white;
        }

        &:before {
          left: -20px;
          border-radius: 0 0 8px 0;
          box-shadow: 10px 0 0 0 $color-white;
        }

        &.dropdown-toggle::before {
          content: unset;
        }

        &.dropdown-toggle::after {
          content: '';
          display: inline-block;
          vertical-align: 0.255em;
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          margin-left: 0.5em;
          position: unset;
          height: unset;
          width: unset;
          bottom: unset;
        }
      }
    }
  }
}
