//colors
$color-primary: #ffd1a0;
$color-bg-primary: linear-gradient(180deg, #ffd1a0 0%, #ff9a33 100%);

$color-grey: rgba(58, 64, 79, 0.7);
$color-gray-100: rgba(58, 64, 79, 0.5);
$color-gray-200: #f8f8f8;
$color-gray-300: rgba(58, 64, 79, 0.3);
$color-gray-400: rgba(58, 64, 79, 0.2);
$color-gray-500: #f2f2f2;
$color-gray-600: #e6e6e6;
$color-black: #3a404f;
$color-dark: #3a404f;
$color-white: #fff;
$color-blue: #ffd1a0;
$color-blue-50: #ffd4b0;
$color-blue-100: #ff9a33;
$color-blue-200: #ffe8c6;
$color-blue-100-opacity-25: #ff99333f;
$color-blue-100-opacity-50: #ff993346;

$color_card_background: #f7f7f7;
$color-green: #43a047;
$color-red: #e53935;
$color-danger: #f4869a;

$color-text-primary: $color-black;
$color-text-secondary: $color-gray-200;

$color-surface-brand-1: #eaeaff;
$color-surface-brand-2: #cbcbfa;
$color-surface-brand-3: #b5b5f5;
$color-surface-brand-4: #9797f1;
$color-surface-brand-5: #7171e7;

$color-surface-negative-1: #fef5f7;
$color-surface-negative-2: #feecf0;
$color-surface-negative-3: #fba7b6;

$color-border-brand-2: #0e9aa4;

$color-icon-brand-2: #0e9aa4;
$color-icon-brand-3: #32bac3;

$color-surface-primary: #fefefe;
$color-surface-secondary: #fafafc;
$color-surface-tetritary-1: #f5f5f7;
$color-surface-tetritary-2: #d8d9dc;
$color-surface-tetritary-3: #adb0b8;

$color-surface-positive: #c3f5ea;
$color-surface-warning: #ffefce;

//borders
$color-border: $color-gray-100;
$color-border-100: $color-gray-300;
$color-border-200: $color-gray-400;
$color-border-300: 1px solid $color-blue-100;
$border-radius: 12px;
$border-radius-100: 8px;

//shadows
$box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
$box-shadow-100: 2px 2px 10px 0px rgba(255, 154, 51, 0.3);
$box-shadow-200: 2px 2px 20px 0px rgba(255, 154, 51, 0.08);
$box-shadow-300: 0px 4px 8px 0px rgba(255, 154, 51, 0.04);

//form
$btn-input-height: 42px;

//Bootstrap overwrites
$body-color: $color-dark;
$body-bg: $color-gray-200;
$font-size-base: 0.875rem;
$font-family-sans-serif: 'Inter', sans-serif;
