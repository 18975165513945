.btn {
  height: $btn-input-height;
  border-radius: $border-radius-100;
  font-weight: 600;
  color: $color-dark;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-inline: 16px;

  a {
    text-decoration: none;
    line-height: normal;
  }

  i {
    font-size: 20px;
  }

  &-primary {
    background: $color-bg-primary;
    box-shadow: $box-shadow-100;
    color: $color-white !important;

    --bs-btn-color: #fff;
    --bs-btn-bg: #ffd1a0;
    --bs-btn-border-color: #ffd1a0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ffb873;
    --bs-btn-hover-border-color: #ff9a33;
    --bs-btn-focus-shadow-rgb: 255, 209, 160;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #ff9a33;
    --bs-btn-active-border-color: #ff7a00;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ffd1a0;
    --bs-btn-disabled-border-color: #ffd1a0;

    a {
      color: $color-white;
    }
  }

  &-secondary {
    background: $color-blue;
    box-shadow: $box-shadow-200;
    color: $color-blue-100;
    border: none;

    &:first-child {
      &:hover,
      &:active,
      &:focus-visible {
        background-color: $color-blue;
        box-shadow: $box-shadow-200;
      }
    }
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: $color-blue;
      box-shadow: $box-shadow-200;
      color: $color-blue-100;
    }

    a {
      color: $color-blue-100;
    }
  }

  &-primary-outline {
    background: $color-surface-primary;
    border-color: $color-blue-200;
    color: $color-text-primary;

    &:hover {
      background: $color-blue-50;
      border-color: $color-blue-200;
    }

    &.active {
      background: $color-blue;
      border-color: $color-blue-200;
    }

    &:focus-visible {
      background: $color-blue-50;
      box-shadow: 0 0 10px 0 $color-blue;
    }

    i {
      color: $color-blue-100;
    }
  }

  &-negative {
    background: $color-surface-negative-1;
    border-color: $color-danger;
    color: $color-text-primary;

    &:hover {
      background: $color-surface-negative-2;
      border-color: $color-danger;
    }

    &:focus-visible {
      background: $color-surface-negative-2;
      box-shadow: 0 0 10px 0 $color-surface-negative-3 !important;
    }

    i {
      color: $color-danger;
    }
  }

  &-outline {
    background: transparent;
    box-shadow: $box-shadow-200;
    color: $color-blue-100;

    &:hover {
      border: 1px solid $color-blue-100;
    }

    a {
      color: $color-blue-100;
    }
  }

  &-action {
    background: $color-gray-200;

    &:hover {
      background: $color-gray-600;
    }
  }

  &-close:focus {
    box-shadow: none;
  }

  &-icon {
    aspect-ratio: 1;
    padding: 4px;

    i {
      font-size: 20px;
    }
  }

  &-sm {
    height: 24px;
  }

  &-round {
    border-radius: 50%;
  }
}
