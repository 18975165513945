.fp-page-header {
  margin-bottom: 15px;
  margin-top: 15px;
}

.fp-page-body {
  padding: 0;
}

label.required:after {
  content: ' *';
  color: red;
}

.fp-menu {
  background: $color-white;

  button {
    display: block;
    width: 100%;
    height: 50px;
    text-align: left;
    padding: 10px 15px;
    margin-bottom: 2px;
    color: black;
    background-color: transparent;
    border: none;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover,
    &:focus {
      color: $color-white;
      background-color: $color-grey;
      text-decoration: none;
    }
    &.active {
      color: $color-white;
      background-color: $color-grey;
      text-decoration: none;
    }
  }
}
